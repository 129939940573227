import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import './css/App.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import "ie-array-find-polyfill";
import cssVars from 'css-vars-ponyfill';

cssVars();
// TODO: à ameliorer
if(document.location.href.indexOf("?") !== -1){
    let queryParams = document.location.href.split("?")[1];
    const queryParamsTab = queryParams.split("&");
    let index = 0;
    let indexType = 0;
    for(let i=0; i<queryParamsTab.length; i++){
        if(queryParamsTab[i].indexOf("config3d_host") !== -1){
            window.config3d_host = decodeURIComponent(queryParamsTab[i].split('=')[1]);
            index = i;
        }
        // Le type d'application Primo ou Selection (valeu par défaut si rien) peut-être passé en paramètre de l' URL
        if(queryParamsTab[i].indexOf("type_app") !== -1){
            window.type_app = decodeURIComponent(queryParamsTab[i].split('=')[1]);
            indexType = i;
        }        
    }
    if(window.config3d_host || window.type_app ){ // On enlève les infos config3d et type_app de l' URL avant de la passer au configurateur
        if (index !==0){
            queryParamsTab.splice(index, 1);
        }
        if (indexType !==0){
            queryParamsTab.splice(indexType, 1);
        }        
        queryParams = '';
        for(let j=0; j<queryParamsTab.length; j++){
            queryParams += queryParamsTab[j];
        }
    }    
    window.gpgCatalogueParams = queryParams;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
