import React, { Component } from 'react';

class Header extends Component {

    render() {
        return (
            <section className="top_Main_Home">
                <p className="titreSaison">Automne 2019</p>

                <div className="bar_Head">
                    <p className="titre_Head" href="" title="">Recettes</p>
                    <div className="breadcrump">{`Extranet > Catalogue > Automne 2019`}</div>
                </div>
            </section>
        );
    }
}

export default Header;
