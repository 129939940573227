import React, { Component } from 'react';

class HeaderPrimo extends Component {

    render() {
        return (
            <section className="top_Main_Home">
                <p className="titreSaison">Primonument {new Date().getFullYear()}</p>
                <div className="bar_Head">
                    <div className="breadcrump">{`Extranet > Catalogue > Primo`}</div>
                </div>
            </section>
        );
    }
}

export default HeaderPrimo;
