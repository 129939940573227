module.exports = {
    getColorName: (color)  => {
        const colorMap = {
            yellow: "Jaune",
            green: "Vert",
            purple: "Violet",
            blue: "Bleu",
            red: "Rouge"
        };
        return colorMap[color] || null;
    }
}
