import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Monuments extends Component {


    render() {
        const {type} = this.props;
        const monuments = this.props.monuments.monuments.sort(function(a, b){
            return a.ordre - b.ordre;
        });

        const addUrl = type === "Primo" ? "/?type_app=Primo":"";
                
        return (
            <div className="les_blocs_recettes">
                {monuments.map((m, i) => {
                    const image = require('../../images/full/'+m.images[0]);
                    const ref = type==="Primo" ? this.props.monuments.tab : this.props.monuments.taille;
                    const label = type==="Primo" ? "A partir de" : "Monuments " + this.props.monuments.taille;
                    return <div key={i} className="bloc_recette">
                        <Link className="image_recette" style={{ textDecoration: 'none', color: 'white' }} to={'/detail/'+ref+'/'+m.modele+'/'+m.ordre+addUrl}><img className="zoom" src={image} alt="" /></Link>
                        <div className="infos_monument">
                            <Link to={'/detail/'+ref+'/'+m.modele+'/'+m.ordre +addUrl}><span></span></Link>
                            <div>
                                <p className="monument_nom">{label}</p>
                                <p className="monument_refprix">{m.designation.split("-")[0]} /<span>{m.defaut.prix} €</span></p>
                            </div>
                        </div>
                    </div>
                    }
                )}
            </div>
        );
    }
}

export default Monuments;