import React, { Component } from 'react';
import Liste from './components/liste/Liste';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Detail from './components/detail/Detail';

class App extends Component {



  render() {
    const type = "Primo"; // window.type_app && window.type_app==="Primo"?"Primo":"Selection";

    return (
      <Router>
        <div>
          <Route exact path="/" render={(props) => <Liste type={type}  {...props} /> } />
          <Route exact path="/tab/:tab" render={(props) => <Liste type={type}  {...props} /> } />
          <Route path="/detail/:tab/:monument/:ordre" render={(props) =><Detail type={type} {...props} />} />
        </div>
      </Router>
    );
  }
}

export default App;
